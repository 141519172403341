import { loadStripe } from '@stripe/stripe-js/pure'
import { nextTick } from 'vue'

import Club from '@/mixins/club-mixin'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  setup() {
    const { defaultHead, defaultSeo, customKey } = useHeadMeta()
    customKey.value = 'pay'
    useHead(defaultHead())
    useSeoMeta(defaultSeo())
    return {}
  },
  mixins: [Club, i18nMx],
  data() {
    const { $currentUser } = useNuxtApp()
    return {
      paymentMethod: 'CARD',
      stripe: undefined,
      card: undefined,
      idealBank: undefined,
      bancontact: undefined,
      iban: undefined,
      payPal: null,
      isStripeLoaded: false,
      isStripeReady: false,
      confirmedAddress: false,
      billingDetails: {
        name: $currentUser()?.fullName,
        email: $currentUser()?.user?.email,
      },
    }
  },
  watch: {
    paymentMethod() {
      this.isStripeReady = false
    },
  },
  computed: {
    stripeApiKey() {
      const { country } = useCurrentLocale()
      return country?.stripeApiKey
    },
  },
  methods: {
    async initStripe(initPayment = true) {
      const { country } = useCurrentLocale()
      // Don't load Stripe for club with BOOK payment policy
      if (!country) {
        // this.$sentry.captureException(new Error('currentCountry undefined in initStripe'))
        console.error('currentCountry undefined in initStripe')
      }
      if (!this.isClub || this.clubEnablePayment) {
        if (!this.stripeApiKey) {
          this.$store.dispatch('snackbar/show', {
            color: 'grapefruit',
            message: this.$t('snackbar.stripeError', { phone: country?.phoneNumber }),
          })
          return
        }
        this.stripe = await loadStripe(this.stripeApiKey)
        this.isStripeLoaded = true
        if (!this.isStripeLoaded) {
          // this.$sentry.captureException(new Error('stripe load error in initStripe'))
          console.error('stripe load error in initStripe')
        }
        if (initPayment) {
          if (country?.paymentMethods[0] === 'IDEAL') {
            this.initIdealPayment()
          } else if (country?.paymentMethods[0] === 'BANCONTACT') {
            this.initBancontactPayment()
          } else {
            this.initCardPayment()
          }
        }
      }
    },
    initPayment(method, minimumAmount, amount = 0) {
      if (method === 'IDEAL') {
        this.initIdealPayment()
      } else if (method === 'BANCONTACT') {
        this.initBancontactPayment()
      } else if (method === 'PAYPAL') {
        if (amount < minimumAmount) return
        this.initPaypalPayment()
      } else {
        this.initCardPayment()
      }
    },
    initCardPayment() {
      if (!this.isStripeLoaded) return
      this.paymentMethod = 'CARD'
      nextTick(() => {
        let options = {
          hidePostalCode: true,
          style: {
            base: {
              color: '#717b8b',
              fontFamily: 'Avenir, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              lineHeight: '2.5',
              '::placeholder': {
                color: 'rgba(113, 123, 139, 1)',
              },
            },
            invalid: {
              color: '#ff7a7a',
              iconColor: '#ff7a7a',
            },
          },
        }
        this.iban = null
        this.idealBank = null
        this.bancontact = null
        const { locale } = useCurrentLocale()
        this.card = this.stripe.elements({ locale: locale }).create('card', options)
        this.card.mount(this.$refs[this.paymentMethod])
        this.card.on('change', event => {
          if (event.error) {
            this.error = event.error.message
          } else {
            this.isStripeReady = event.complete
            this.error = null
          }
        })
      })
    },
    initIdealPayment() {
      if (!this.isStripeLoaded) return
      this.paymentMethod = 'IDEAL'
      nextTick(() => {
        let options = {
          style: {
            base: {
              color: '#717b8b',
              fontFamily: 'Avenir, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              lineHeight: '2.5',
              '::placeholder': {
                color: 'rgba(113, 123, 139, 1)',
              },
              padding: '5px 15px',
            },
            invalid: {
              color: '#ff7a7a',
              iconColor: '#ff7a7a',
            },
          },
        }
        this.iban = null
        this.card = null
        const { locale } = useCurrentLocale()
        this.idealBank = this.stripe.elements({ locale: locale }).create('idealBank', options)
        this.idealBank.mount(this.$refs[this.paymentMethod])
        this.idealBank.on('change', event => {
          if (event.error) {
            this.error = event.error.message
          } else {
            this.isStripeReady = event.complete
            this.error = null
          }
        })
      })
    },
    initBancontactPayment() {
      if (!this.isStripeLoaded) return
      this.paymentMethod = 'BANCONTACT'
      nextTick(() => {
        this.iban = null
        this.card = null
        this.idealBank = null
        this.isStripeReady = true
      })
    },
    initPaypalPayment() {
      if (!this.isStripeLoaded) return
      this.paymentMethod = 'PAYPAL'
      nextTick(() => {
        this.iban = null
        this.card = null
        this.idealBank = null
        this.bancontact = null
        this.isStripeReady = true
      })
    },
    initSEPAPayment() {
      if (!this.isStripeLoaded || this.paymentMethod === 'SEPA') return

      this.paymentMethod = 'SEPA'
      nextTick(() => {
        let options = {
          supportedCountries: ['SEPA'],
          placeholderCountry: 'FR',
        }
        this.card = null
        this.idealBank = null
        this.bancontact = null
        const { locale } = useCurrentLocale()
        this.iban = this.stripe.elements({ locale: locale }).create('iban', options)
        this.iban.mount(this.$refs[this.paymentMethod])
        this.iban.on('change', event => {
          if (event.error) {
            this.error = event.error.message
          } else {
            this.isStripeReady = event.complete
            this.error = null
          }
        })
      })
    },
  },
}
